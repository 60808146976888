import React from "react"
import { Formik } from "formik"
const axios = require('axios');


const CreateJob = () => {
    
      
      
      
  return (
    <div className="max-w-3xl mx-auto py-9 mt-9">
      <h2 className="text-2xl mb-4 ">Create a New Job</h2>
      <div>
        <Formik
          initialValues={{ name: ""}}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              
              
            }, 400)
            let config = {
                method: 'post',
                url: 'https://64yecfqnn0.execute-api.us-east-1.amazonaws.com/prod/jobs',
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : {...values, type: "sendEmail"}
              };
              
              axios(config)
              .then((response) => {
                alert(JSON.stringify(response.data));

                setSubmitting(false)
              })
              .catch((error) => {
                console.log(error);
                setSubmitting(false)
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    value={values.name}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="job name"
                  />
                </div>
                {/* <label
                  htmlFor="desc_test"
                  className="block text-sm font-medium text-gray-700"
                >
                  Desc
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="desc_test"
                    id="desc_test"
                    onChange={handleChange}
                    value={values.desc_test}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="job desc"
                  />
                </div> */}
              </div>
              <button type="submit" disabled={isSubmitting} className="my-2 px-3 py-2 bg-blue-600 rounded text-white">
                Submit
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default CreateJob
