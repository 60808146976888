import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { FiCalendar, FiUsers } from "react-icons/fi"
const axios = require("axios")

const JobsList = () => {
  const [jobs, setJobs] = useState([])
  let config = {
    method: "get",
    url: "https://64yecfqnn0.execute-api.us-east-1.amazonaws.com/prod",
    headers: {},
  }

  useEffect(() => {
    axios(config)
      .then(response => {
        console.log(JSON.stringify(response.data))
        setJobs(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  //  {
  //     "id": 43,
  //     "created_at": "2022-02-11T18:30:30.848379+00:00",
  //     "type": "sendEmail",
  //     "isRecurring": true,
  //     "nextExecutionTime": 1645209062,
  //     "frequency": "weekly",
  //     "isActive": true,
  //     "succesfulExecutions": 1,
  //     "name": "Send Email Weekly"
  // },

  jobs.length < 0 && <div>loading...</div>

  return (
      <div className=" max-w-3xl mx-auto">
    <h2 className='text-2xl my-3'>Jobs</h2>
    <div className="bg-white shadow overflow-hidden sm:rounded-md max-w-3xl mx-auto">
        
      <ul role="list" className="divide-y divide-gray-200">
        {jobs.map(position => (
          <li key={position.id}>
            <a href="#" className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    {position.name}
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {position.type}
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    {position.desc_test && (
                      <p className="flex items-center text-sm text-gray-500">
                        {position.desc_test}
                      </p>
                    )}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    {position.nextExecutionTime && (
                      <>
                        {" "}
                        <FiCalendar
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Next execution{" "}
                          <time dateTime={position.nextExecutionTime}>
                            {dayjs
                              .unix(position.nextExecutionTime)
                              .format("MM/DD/YYYY HH:mm:ss")}
                          </time>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
    </div>
  )
}

export default JobsList
