import React from "react"
import CreateJob from "../components/create-job"
import JobsList from "../components/jobs-list"

export default function Home() {
  return (
    <div>
      {" "}
      <h1 className="text-3xl font-bold underline">Hello world!</h1>
      <JobsList />
      <CreateJob />
    </div>
  )
}
